<template>
  <div class="errorpage">
    <app-lottie
      :lottie="'404'"
      :loop="true"
      class="errorpage__animation"
    ></app-lottie>
    <div class="errorpage__text-box">
      <h2 class="errorpage__alert">{{ $t("errorpage")["page_not_found"] }}</h2>
      <p class="errorpage__description">
        {{ $t("errorpage")["paragraph"][0] }}
        <app-button
          type="button"
          :text="$t('errorpage')['paragraph'][1]"
          variation="transparent"
          @click="$router.go(-1)"
          class="errorpage__button"
        ></app-button>
        {{ $t("errorpage")["paragraph"][2] }}
        <app-button
          type="button"
          :text="$t('errorpage')['paragraph'][3]"
          variation="transparent"
          @click="goTo('/')"
          class="errorpage__button"
        ></app-button>
      </p>
    </div>
    <app-footer class="errorpage__footer"></app-footer>
  </div>
</template>

<script>
import AppLottie from "@/components/Views/Lottiecontainer.vue";
import AppButton from "@/components/Atoms/Button.vue";
import AppFooter from "@/components/Templates/Footer.vue";

export default {
  name: "errorpage",
  components: {
    AppLottie,
    AppButton,
    AppFooter
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.errorpage {
  position: relative;
  width: 100%;
  min-height: calc(100vh + 6rem);
  padding-top: 20vh;

  @include respond(phone) {
    padding-top: 13.9rem;
  }

  &__animation {
    transform: translateX(calc(50vw - 50%));
    max-width: min(75rem, 100% - 3rem);
  }

  &__text-box {
    padding-bottom: 5rem;
    text-align: center;
    color: $color-white;
  }

  &__alert {
    margin-top: 5rem;
    font-family: $font-primary-bold;
    font-size: 5rem;
    line-height: 1;
  }

  &__description {
    margin-top: 2.7rem;
    transform: translateX(calc(50vw - 50%));
    padding: 0 1.5rem;
    max-width: 60rem;
    font-size: 2.8rem;
    line-height: 1.5;

    @include respond(phone) {
      margin: 3.3rem 0 5.5rem;
      font-size: 2.4rem;
    }

    & button {
      font-family: $font-primary;
      font-size: 2.8rem;
      text-decoration: underline;

      @include respond(phone) {
        font-size: 2.4rem;
      }
    }

    & .button:focus.button:not(:active)::after {
      height: 3.7rem;
    }
  }

  &__button {
    display: inline-block;
    padding: 0;
    white-space: nowrap;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $color-black;
  }
}
</style>
